/* font-family: 'Lato', sans-serif; */

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.character-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-left: 1px solid #eee;
}

.character .symbol {
  cursor: pointer;
  font-size: 5em;
  /* position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  vertical-align: middle;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; */
}

.character {
  position: relative;
  border-right: 1px solid #eee;
  border-bottom: 1px solid #eee;
  text-align: center;
  background-color: #fff;
  color: #464646;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  overflow: hidden;
}

.character .character__info {
  display: none;
}

.character {
  width: calc(100% / 5);
}

.emoji {
  /* font-family: "Noto Color Emoji","Apple Color Emoji","Segoe UI Emoji",Times,Symbola,Aegyptus,Code2000,Code2001,Code2002,Musica,emoji !important; */
  font-family: "Noto Color Emoji", "Apple Color Emoji", "Segoe UI Emoji", Times,
    Symbola, Aegyptus, Code2000, Code2001, Code2002, Musica, emoji !important;
}

span {
  font: inherit;
}

.character > .symbol {
  font-family: "Noto Color Emoji", "Apple Color Emoji", "Segoe UI Emoji", Times,
    Symbola, Aegyptus, Code2000, Code2001, Code2002, Musica, emoji !important;
}

.center {
  display: flex;
  justify-content: center;
}

@media only screen and (min-width: 401px) {
  .character {
    width: calc(100% / 6);
  }
}
@media only screen and (min-width: 501px) {
  .character {
    width: calc(100% / 7);
  }
}
@media only screen and (min-width: 601px) {
  .character {
    width: calc(100% / 8);
  }
}
@media only screen and (min-width: 701px) {
  .character {
    width: calc(100% / 9);
  }
}
@media only screen and (min-width: 801px) {
  .character {
    width: calc(100% / 10);
  }
}
@media only screen and (min-width: 901px) {
  .character {
    width: calc(100% / 11);
  }
}
@media only screen and (min-width: 1001px) {
  .character {
    width: calc(100% / 12);
  }
}

.custon-nav-col {
  /* margin-top: auto; */
}

.row {
  position: relative;
}

.robinFace {
  vertical-align: baseline !important;
}

.bottom-align-text {
  position: absolute;
  bottom: 0;
  right: 0;
}

.titolo {
  font-family: "Lato Black", sans-serif;
  color: white;
  text-transform: capitalize;
  margin-top: auto;
  margin-bottom: auto;
  /* font-size: 2.3rem; */
  font-size: 2.7rem;
  /*font-weight: bold !important;*/
}

.titolo2 {
  font-family: "Lato", sans-serif;
  color: white;
  text-transform: capitalize;
  margin-top: auto;
  margin-bottom: auto;
  /* font-size: 2.3rem; */
  font-size: 2.7rem;
  font-weight: normal !important;
}

/* .custom-row {
  display: table;
} */

.sottotitolo {
  font-family: "Lato", sans-serif;
  color: #d3d3d3;
  text-align: left;

  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.custom-nav {
  background: #1a1a1a !important;
  padding: 1.5rem 1rem;
}

p {
  margin-bottom: 0 !important;
  margin-top: null !important;
}

/* On screens that are 992px or less, set the background color to blue */
@media screen and (min-width: 992px) {
  .position-relative {
    position: relative;
  }

  .postition-bottom {
    position: absolute;
    bottom: 0;
  }
}

@media screen and (max-width: 992px) {
  .sottotitolo {
    text-align: center;
  }

  .nav-tabs > .nav-item {
    min-width: 100px;
  }

  .nav-tabs {
    text-align: left;
  }
}

@media screen and (max-width: 600px) {
  .character .symbol {
    cursor: pointer;
    font-size: 3em;
  }
}

.pateron-img {
  width: 200px;
  height: 55px;
  display: "inline" !important;
}

@media (min-width: 320px) {
  .pateron-img {
    width: 200px;
    height: 55px;
    display: "block" !important;
  }
}

@media screen and (max-width: 600px) {
  .footer-section .column {
    display: flex !important;
    flex-direction: column !important;
  }

  .pateron-img {
    margin: 10px 0;
  }
}

.bn {
  cursor: pointer;
  display: inline-block;
  min-height: 1em;
  outline: none;
  border: none;
  vertical-align: baseline;
  background: #e0e1e2 none;
  color: rgba(0, 0, 0, 0.6);
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  margin: 0 0.25em 0 0;
  padding: 0.78571429em 1.5em 0.78571429em;
  text-transform: none;
  text-shadow: none;
  font-weight: 700;
  line-height: 1em;
  font-style: normal;
  text-align: center;
  text-decoration: none;
  border-radius: 0.28571429rem;
  -webkit-box-shadow: 0 0 0 1px transparent inset,
    0 0 0 0 rgba(34, 36, 38, 0.15) inset;
  box-shadow: 0 0 0 1px transparent inset, 0 0 0 0 rgba(34, 36, 38, 0.15) inset;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: opacity 0.1s ease, background-color 0.1s ease,
    color 0.1s ease, background 0.1s ease, -webkit-box-shadow 0.1s ease;
  transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease,
    background 0.1s ease, -webkit-box-shadow 0.1s ease;
  transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease,
    box-shadow 0.1s ease, background 0.1s ease;
  transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease,
    box-shadow 0.1s ease, background 0.1s ease, -webkit-box-shadow 0.1s ease;
  will-change: "";
  -webkit-tap-highlight-color: transparent;
}

.bn:focus {
  outline: none;
}

.p-12 {
  padding-top: 12px;
}
